import {
  Button,
  Flex,
  Switch,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { MISSION_BONUS_TYPE, ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { axiosPost } from "utils/api";
import moment from "moment";

function SeasonTableRow({ row, isLast, refetchData }) {
  const [isPublic, setIsPublic] = useState(row?.status);
  const history = useHistory();
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();

  const handleSuccess = (message, isRefetch) => {
    toast({
      title: message,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    isRefetch && refetchData();
  };

  const handleError = (message) => {
    toast({
      title: message,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleDeleteClick = async () => {
    if (window.confirm("Are you sure to delete this mission?")) {
      const data = {
        missionId: row._id,
      };
      try {
        const response = await axiosPost(
          ROOT_API + API_ROUTES.DELETE_MISSION_BONUS,
          data
        );
        if (response?.data?.code === 0) {
          handleSuccess("Delete Mission Bonus Successfully", true);
        }
      } catch (error) {
        handleError(
          error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete Mission Bonus Fail"
        );
      }
    }
  };

  const handlePublicPost = async (status) => {
    try {
      const response = await axiosPost(
        `${ROOT_API}${API_ROUTES.STATUS_MISSION}`,
        {
          missionId: row?._id,
        }
      );
      if (response?.data?.code === 0) {
        handleSuccess("Change Status Mission Successfully");
        setIsPublic(status);
      }
    } catch (error) {
      handleError(
        error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Change Status Mission Fail"
      );
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text isTruncated fontSize="md" color={textColor} fontWeight="bold">
              {row?._id}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.type}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row?.status ? "Bật" : "Tắt"}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {moment.utc(row?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}
            </Text>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default SeasonTableRow;
