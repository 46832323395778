import React, { useRef, useState } from "react";
import {
  Flex,
  Table,
  Button,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,
  InputGroup,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { MISSION_DAILY, MISSION_TYPE, ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import useAxios from "axios-hooks";
import { initialFilter } from "utils/constant";
import SeasonTableRow from "./components/Row";
import { TablePagination } from "@trendmicro/react-paginations";
import Loading from "components/Layout/Loading";
import { Select } from "chakra-react-select";

const Config = () => {
  const history = useHistory();
  const refSearchButton = useRef(null);
  const params = useParams();
  const toast = useToast();
  const [filter, setFilter] = useState(initialFilter);
  const [searchValue, setSearchValue] = useState("");
  const [point, setPoint] = useState(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [sort, setSort] = useState({
    type: null,
    dailyType: null,
  });

  const [{ loading: createConfigLoading }, createConfig] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CREATE_CONFIG,
    },
    { manual: true }
  );

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${ROOT_API}${API_ROUTES.CONFIG_ALL}`,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const handleSuccess = () => {
    refetch?.();
    toast({
      title: `Create Config Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleError = (error) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `Create Config Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleCreateConfig = () => {
    createConfig(undefined)
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Config
            </Text>
          </CardHeader>
          <CardBody pb={4} overflowX="hidden">
            <Flex mb={3} columnGap={2} justifyContent={"space-between"}>
              <Box display={"flex"} alignItems={"center"} gap={5}>
                <Button colorScheme="blue" onClick={handleCreateConfig} isLoading={createConfigLoading}>
                  Change status config
                </Button>
              </Box>
            </Flex>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Box overflowX={"scroll"}>
                  <Table variant="simple" color={textColor}>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.400">
                        <Th borderColor={borderColor} color="gray.400">
                          ID
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Loại
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Trạng thái
                        </Th>
                        <Th
                          borderColor={borderColor}
                          color="gray.400"
                          isTruncated
                        >
                          Ngày tạo
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.length
                        ? data?.data?.map((row, index, arr) => {
                            return (
                              <SeasonTableRow
                                row={row}
                                isLast={index === arr.length - 1 ? true : false}
                                key={index}
                                refetchData={refetch}
                                //   handelClickRow={handelClickRow}
                              />
                            );
                          })
                        : ""}
                    </Tbody>
                  </Table>
                </Box>
                <Flex justifyContent={"flex-end"}>
                  <TablePagination
                    type="full"
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={({ page, pageLength }) => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default Config;
